import React from 'react';
import styled from 'styled-components';
import { RichText } from '@graphcms/rich-text-react-renderer';

const Wrapper = styled.span`
  font-size: 46px;
  line-height: 58px;
  white-space: pre-wrap;
  font-family: ${({ small, theme }) =>
    small ? theme.fontSerif : theme.fontSans};
  font-size: ${({ small }) => (small ? 29 : 46)}px;
  line-height: ${({ small }) => (small ? 29 : 58)}px;
  padding-bottom: ${({ small }) => (small ? 15 : 30)}px;
  display: block;

  a {
    text-decoration: underline;
  }

  p,
  ul,
  ol {
    margin-bottom: ${({ small }) => (small ? 25 : 0)}px;
    
    @media only screen and (max-width: 810px) {
      margin-bottom: ${({ small }) => (small ? 15 : 0)}px;
  }
  }

  strong {
    //TODO: change font to bolder
    font-weight: bold;
  }

  @media only screen and (max-width: 810px) {
    font-size: ${({ small }) => (small ? 16 : 23)}px;
    line-height: ${({ small }) => (small ? 20 : 30)}px;
  }
`;

const RichTextWrapper = ({ content, small }) => {
  return (
    <Wrapper small={small}>
      <RichText content={content} />
    </Wrapper>
  );
};

export default RichTextWrapper;
