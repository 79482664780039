import React, { useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';

import W from '../components/styled/Wrapper';
import H from '../components/styled/Heading';
import P from '../components/styled/Paragraph';
import RichText from '../components/offer/InfoRichText';

import AccordionGroup from '../components/shared/AccordionGroup';
import Footer from '../components/layout/Footer';

const Wrapper = styled(W)``;

const TextWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};

  @media only screen and (max-width: 810px) {
    border-width: 1px;
  }
`;

const Heading = styled(H)``;

const SmallTextWrapper = styled.div`
  max-width: 900px;
`;

const Home = ({
  title,
  intro,
  bigText,
  smallText,
  items,
  seoTitle,
  seoDescription,
  seoImage,
  contextSet,
  langTo,
  contact,
}) => {
  useEffect(() => {
    contextSet({ langTo: langTo });
  }, []);

  return (
    <React.Fragment>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <Wrapper>
        <TextWrapper>
          <Heading size={1} as={'h1'}>
            {title}
          </Heading>
          {bigText && <RichText content={bigText.raw} />}

          {smallText && (
            <SmallTextWrapper>
              <RichText content={smallText.raw} small />
            </SmallTextWrapper>
          )}
        </TextWrapper>
        <AccordionGroup items={items} small={true} />
        <Footer />
      </Wrapper>
    </React.Fragment>
  );
};

export default Home;
