import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Accordion from './SmallAccordion';

const Wrapper = styled.div`
  margin-bottom: 96px;

  @media only screen and (max-width: 810px) {
    margin-bottom: 36px;
  }
`;

const AccordionGroup = ({ items, noMargin, small }) => {
  const [open, setOpen] = useState([]);

  useEffect(() => {
    if (items && items.length > 0) {
      let states = [];
      items.forEach((element, i) => {
        states.push(false);
      });

      setOpen(states);
    }
  }, [items]);

  const update = (index, value) => {
    let states = [];

    if (items && items.length > 0) {
      items.forEach((element, i) => {
        states.push(value ? i === index : false);
      });

      setOpen(states);
    }
  };

  return (
    <Wrapper noMargin={noMargin}>
      {items &&
        items.length > 0 &&
        items.map((q, i) => {
          return (
            <Accordion
              key={`${q.title}${i}`}
              open={open[i] && open[i]}
              first={i === 0}
              index={i}
              title={q.title}
              text={q.paragraph}
              update={update}
              small={small}
            />
          );
        })}
    </Wrapper>
  );
};

export default AccordionGroup;
