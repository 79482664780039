import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';

import P from '../styled/Paragraph';
import H from '../styled/Heading';
import B from '../styled/Button';

import XSvg from '../../assets/x.svg';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};

  @media only screen and (max-width: 810px) {
    border-width: 1px;
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

const Title = styled.button`
  position: relative;
  width: 100%;
  text-align: left;
  padding: 8px 100px 10px 0;
  transition: padding 0.15s ease-in-out;

  @media only screen and (max-width: 810px) {
    padding: 4px 30px 4px 0;
  }
`;

const TitleText = styled(H)`
  line-height: 1;
  padding-top: 5px;
  font-size: 29px;
  line-height: 35px;

  @media only screen and (max-width: 810px) {
    line-height: 1;
  }
`;

const OpenClose = styled.div`
  position: absolute;
  right: 0;
  top: 21px;
  transition: transform 0.15s ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0deg)')};

  @media only screen and (max-width: 810px) {
    top: 14px;
  }
`;

const X = styled(XSvg)`
  height: 16px;
  width: 16px;

  @media only screen and (max-width: 810px) {
    height: 18px;
    width: 18px;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const Text = styled(({ showBg, ...rest }) => <animated.div {...rest} />)`
  overflow: hidden;
`;

const TextInner = styled(P)`
  padding: 0 0 30px;
  max-width: 775px;

  @media only screen and (max-width: 810px) {
    padding: 5px 30px 15px 0;
  }
`;

const SmallAccordion = ({
  title,
  text,
  first = false,
  open,
  update,
  index,
  data,
  isEn,
}) => {
  const [hovered, setHovered] = useState(false);
  const [textRef, { height }] = useMeasure({ polyfill: ResizeObserver });

  const springProps = useSpring({
    height: open ? height : 0,
    // config: { duration: 300 },
  });
  return (
    <Wrapper first={first}>
      <Title
        // onClick={() => {
        //   open && update(index, !open);
        // }}
        open={open}
        onMouseEnter={() => update(index, !open)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <TitleText size={1}>{title}</TitleText>
        <OpenClose
          open={open}
          onClick={() => {
            open && update(index, !open);
          }}
        >
          <X />
        </OpenClose>
      </Title>
      <Text style={{ ...springProps }}>
        <div ref={textRef}>
          <TextInner>{text}</TextInner>
        </div>
      </Text>
    </Wrapper>
  );
};

export default SmallAccordion;
